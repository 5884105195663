import React, { useContext } from 'react'
import { GlobalContext } from '../context/GlobalState'

export const Balance = () => {
  const { transactions } = useContext(GlobalContext)
  const amounts = transactions.map(transaction => transaction.amount)
  const totalAmount = amounts.reduce((acc, item) => (acc + item), 0).toFixed(2);
  const sign = (totalAmount >= 0) ? "+" : "-"

  return (
    <div>
      <h4>あなたの収支</h4>
      <h1>{sign}${Math.abs(totalAmount)}</h1>
    </div>
  )
}

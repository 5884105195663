import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../context/GlobalState';

export const AddTransaction = () => {
  const [text, setText] = useState("");
  const [amount, setAmount] = useState(0);

  const { transactions, addTransaction } = useContext(GlobalContext)
  const focusOnText = () => {
    document.getElementById('transactionText').focus()
  }

  const onSubmit = e => {
    e.preventDefault();

    const newTransaction = {
      id: transactions.length + 1,
      text,
      amount: Number(amount)
    }
    addTransaction(newTransaction)
    setText("")
    setAmount(0)
    focusOnText()
  }

  useEffect(() => {
    focusOnText()
  }, [])

  return (
    <>
    <h3>収支を入力</h3>
    <form onSubmit={onSubmit}>
      <div className="form-control">
        <label htmlFor="transactionText">用途</label>
        <input type="text" id="transactionText"
          value={text}
          onChange={e => setText(e.target.value)}
          placeholder="入力してください。" 
        />
      </div>
      <div className="form-control">
        <label htmlFor="transactionAmount">
          金額 <br />
          <small>(支出なら-○○と入力)</small>
        </label>
        <input type="number" id="transactionAmount"
          value={amount}
          onChange={e => setAmount(e.target.value)}
          placeholder="Enter amount..."
        />
      </div>
      <button className="btn">追加</button>
    </form>
    </>
  )
}
